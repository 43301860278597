/* src/components/Common/Menu/SiraafMenu.module.css */
.siraafmenu {

}

.level1Box{
    display: flex;
    flex-direction: row;
}

.dropdown{
    position: absolute;
    display: flex;
    background: #f1f2f2;
    padding-top: 15px;
    box-shadow: 0px -4px 20px 0px rgb(241 242 242 / 58%);
    left:0;
    right:0;
}

.level2Box{
    border-color: #e1e1e1;
}

.level1BoxItem{

}

.level1BoxItemInactive{
    cursor: pointer;
    line-height: 2.5rem;
    align-items: center;
}

.level1BoxItemActive{
    cursor: pointer;
    line-height: 2.5rem;
    align-items: center;
}


.level2BoxItemActive{
    background-color: #fff;
    border-radius: .6rem;
    cursor: pointer;
}

.level2BoxItemInactive{
    border-radius: .6rem;
    cursor: pointer;
}

.level3Box{
    display: block;
}

.menuList {
    list-style-type: none;
    padding-left: 0;
}

.menuItem {
    margin: 5px 0;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.menuItem:hover {
    background-color: #ddd;
}

.menuLabel {
    font-weight: bold;
}
.menuLabelL2{
    color: #aaa;
}

 

.menuContainerActive > div {
    display: block;
    animation: slideDownFadeIn 0.5s ease forwards;
}

.menuContainerInactive > div {
    display: none;
}

@keyframes slideDownFadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}



.masonry {
    display: flex !important;
    flex-wrap: wrap;
    gap: 1rem; /* Adjust the gap between items */
}

.masonryItem {
    flex: 1 1 calc(33.333% - 1rem); /* Adjust the percentage for the number of columns */
    margin-bottom: 1rem;
}